/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Typography,
  VariableContent,
  LinkButton,
  useMapi,
  Link,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { Form } from '@leshen/ui'

import header from '../components/Header'
import footer from '../components/Footer'
const { EmilyClient } = require('@clearlink/emily')

const Home = ({ location }) => {
  const disclaimerTcpa = 'testing this disclaimer'

  const { requestId, sessionPromoCode, rotatedNumber } = useMapi()
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false)
  const [data, setData] = useState(null)
  const [policyCodeValid, setPolicyCodeValid] = useState(false)
  const [policyCancelled, setPolicyCancelled] = useState(false)
  const searchParams = new URLSearchParams(location.search)
  const policyCode = searchParams.get('enrollment_code')

  let promoCode = sessionPromoCode === 'SAMPLEPROMO' ? 147165 : sessionPromoCode
  useEffect(() => {
    const apiUrl = `https://aim.clearlink.com/api/enrollments/v1/enrollment/${policyCode}`

    fetch(apiUrl)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        const planStatus = data.data.current_status
        setData(data.data)
        setPolicyCodeValid(true)
      })
      .catch((error) => {
        console.error(error)
        setPolicyCodeValid(false)
        setPolicyCancelled(true)
      })
  }, [])

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Clearlink Insurance Agency | Insurance Quotes | 833-488-0038',
          description:
            'Call Clearlink Insurance Agency - Your one-stop-shop for Auto, Home, Supplemental Health, & Medicare Insurance! Our Agents are ready to provide you quotes from multiple partners in order to get you the rate you deserve.',
          canonical: 'https://clearlinkinsurance.com/policy-status',
          robots: 'follow,index',
        },
        path: '/policy-status',
        promoCode: '169449',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        disclaimer: 'this is the disclaimer i want',
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'clearlinkinsurance',
    defaultRobots: 'follow,index',
    main: (
      <>
        {policyCodeValid ? (
          <div className="policy-tracker">
            <VariableContent
              className="desktop"
              mainContent={
                <>
                  <Typography variant="h1">
                    Policy Tracker : {policyCode}
                  </Typography>
                </>
              }
            >
              {data?.current_status === 'SUBMITTED' && (
                <StaticImage
                  src="../images/policy-tracker/submitted.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'PENDING' && (
                <StaticImage
                  src="../images/policy-tracker/pending.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'APPROVED' && (
                <StaticImage
                  src="../images/policy-tracker/approved.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'ACTIVE' && (
                <StaticImage
                  src="../images/policy-tracker/active.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'CANCELLED' && (
                <StaticImage
                  src="../images/policy-tracker/cancelled.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </VariableContent>
            <VariableContent
              className="mobile"
              mainContent={
                <>
                  <Typography variant="h1">
                    Policy Tracker : {policyCode}
                  </Typography>
                </>
              }
            >
              {data?.current_status === 'SUBMITTED' && (
                <StaticImage
                  src="../images/policy-tracker/submitted-mobile.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'PENDING' && (
                <StaticImage
                  src="../images/policy-tracker/pending-mobile.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'APPROVED' && (
                <StaticImage
                  src="../images/policy-tracker/approved-mobile.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'ACTIVE' && (
                <StaticImage
                  src="../images/policy-tracker/active-mobile.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
              {data?.current_status === 'CANCELLED' && (
                <StaticImage
                  src="../images/policy-tracker/cancelled-mobile.png"
                  alt={`Policy status is ${data?.current_status.toLowerCase()}`}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </VariableContent>
            {data?.current_status !== 'CANCELLED' && (
              <div className="policy-card-container">
                <div className="policy-image">
                  {data?.brand === 'aetna' && (
                    <StaticImage
                      src="../images/brand-logos/aetna.svg"
                      alt="Aetna logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                  {data?.brand === 'cigna' && (
                    <StaticImage
                      src="../images/brand-logos/cigna.svg"
                      alt="Cigna logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                  {data?.brand === 'regence' && (
                    <StaticImage
                      src="../images/brand-logos/regence.svg"
                      alt="Regence logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                  {data?.brand === 'united' && (
                    <StaticImage
                      src="../images/brand-logos/united-healthcare.svg"
                      alt="United HealthCare Logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                  {data?.brand === 'wellcare' && (
                    <StaticImage
                      src="../images/brand-logos/wellcare.svg"
                      alt="Wellcare logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                  {data?.brand === 'mutual' && (
                    <StaticImage
                      src="../images/brand-logos/mutual-omaha.svg"
                      alt="Mutula of Omaha logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                  {data?.brand === 'humana' && (
                    <StaticImage
                      src="../images/brand-logos/humana.svg"
                      alt="Humana logo"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                </div>
                {data?.current_status !== 'CANCELLED' && (
                  <div className="policy-info">
                    <Typography variant="body">Plan Type:</Typography>
                    <Typography variant="body">
                      <strong>{data?.plan_type}</strong>
                    </Typography>
                    <Typography variant="body">
                      Policy Effective Date:
                    </Typography>
                    <Typography variant="body">
                      <strong>{data?.effective_date}</strong>
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {data?.current_status === 'CANCELLED' && (
              <div className="resubmit-button-container">
                <VariableContent
                  alignMainContent="Center"
                  alignImageToBottom={false}
                  centeredContent={false}
                  backgroundColor={null}
                  customLink={null}
                >
                  <LinkButton to={`tel:${rotatedNumber}`} color="secondary">
                    Contact Us
                  </LinkButton>
                  <LinkButton to={`/confirmation`} color="secondary">
                    Resubmit
                  </LinkButton>
                </VariableContent>
              </div>
            )}
            <div className="sms-form" style={{ marginBottom: '20px' }}>
              <VariableContent
                backgroundColor=""
                alignMainContent="center"
                style={{ paddingBottom: '0px', paddingTop: '20px' }}
                mainContent={
                  <>
                    <Typography
                      variant="h4"
                      style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                      Want to receive policy status updates by phone?
                    </Typography>
                  </>
                }
              >
                <Form
                  formId={null}
                  formSource="eloqua"
                  submitButtonText="Submit"
                  submitButtonColor="primary"
                  submitButtonOutlined={false}
                  submitButtonGradient={false}
                  inlineButton={false}
                  formName="sms-consent"
                  onSubmit={async ({
                    values,
                    setShowSubmissionMessage,
                    setShowSubmissionErrorMessage,
                  }) => {
                    const mergedValues = {
                      ...values,
                      brand: 'CI',
                      elqFormName: 'sms-consent',
                      origin: 'https://www.clearlinkinsurance.com',
                      domain: 'https://www.clearlinkinsurance.com',
                      promo_code: promoCode,
                      request_id: requestId,
                      source: 'sms-consent',
                    }

                    try {
                      const emilyClient = new EmilyClient(
                        'https://leads.clear.link'
                      )

                      const response = await emilyClient.submit(mergedValues)

                      if (response.status === 200) {
                        setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                      } else {
                        setShowSubmissionMessage(false)
                      }
                      setShowSubmissionMessage(true)
                    } catch (error) {
                      // If an error occurs:
                      console.error('Submission error:', error)
                      setShowSubmissionMessage(false)
                      setShowSubmissionErrorMessage(true)
                    }
                  }}
                  emilyTCPAUpdate={() => {}}
                  steps={null}
                  showSubmissionMessage={showSubmissionMessage}
                  fields={[
                    {
                      name: 'phone',
                      type: 'tel',
                      label: 'Phone',
                      required: true,
                    },
                  ]}
                  isMultiStep={false}
                  heading={null}
                  subheading={null}
                  tcpaCheckbox={true}
                  formDisclaimerPosition="Below Submit Button"
                  disclaimer={{
                    brandy: {
                      text: 'I agree',
                      symbol: '',
                    },
                  }}
                  customSubmissionMessage={
                    <>
                      <span className="sms-form-thank-you">
                        Thank you! You can expect to receive a message once
                        there’s an update to your policy application.
                      </span>
                    </>
                  }
                  initialStepValues={{}}
                  smartyStreetsWebsiteKey={null}
                  addressQualificationCode={null}
                  spanish={false}
                  requestId={null}
                />
                <Typography
                  variant="body"
                  style={{
                    marginBottom: '0px',
                    maxWidth: '450px',
                    margin: '0 auto',
                  }}
                >
                  Thank you for choosing Clearlink Insurance Agency. By signing
                  up to receive information about your policy by text you agree
                  to receive text messages from Clearlink Insurance Agency about
                  your account to the number you provide during the signup,
                  including possibly using automated technology. Your consent is
                  not required to make a purchase from us.{' '}
                  <Link to="/terms">Terms and Privacy Policy.</Link>
                </Typography>
              </VariableContent>
            </div>
          </div>
        ) : (
          <div className="purple-triangle-edge-background white-text">
            <VariableContent
              mainContent={
                <>
                  <Typography variant="h2">
                    Enrollment code: {policyCode} not found
                  </Typography>
                  <Typography variant="h5">
                    Please check your code and try again
                  </Typography>
                </>
              }
              alignMainContent="Center"
              alignImageToBottom={false}
              centeredContent={false}
              backgroundColor={null}
              customLink={null}
            >
              <div className="policy-check-form">
                <Form
                  formId={null}
                  formSource="eloqua"
                  submitButtonText="Submit"
                  submitButtonColor="primary"
                  submitButtonOutlined={false}
                  submitButtonGradient={false}
                  inlineButton={false}
                  formName={null}
                  onSubmit={async ({ values }) => {
                    window.location.href = `/policy-status?enrollment_code=${values.code}`
                  }}
                  emilyTCPAUpdate={() => {}}
                  steps={null}
                  showSubmissionMessage={showSubmissionMessage}
                  fields={[
                    {
                      name: 'code',
                      type: 'text',
                      label: 'Enrollment Code',
                      value: `${policyCode || ''}`,
                    },
                  ]}
                  isMultiStep={false}
                  subheading={null}
                  tcpaCheckbox={false}
                  disclaimer={null}
                  initialStepValues={{}}
                  smartyStreetsWebsiteKey={null}
                  addressQualificationCode={null}
                  spanish={false}
                  requestId={null}
                />{' '}
              </div>
            </VariableContent>
          </div>
        )}
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
